<template>
	<div>
		<form @submit.prevent="submit" class="update-password-page">
			
			<h1 class="text-4xl font-bold mb-1 leading-tight">Update Password</h1>
			<div class="text-sm text-gray-600 mb-4">Hi {{ firstName }}, enter a new password to proceed.</div>

			<template v-if="getFormError(form)">
				<div class="alert alert-red-soft">
					<span class="alert-icon">!</span>
					<span>{{ getFormError(form) }}</span>
				</div>
			</template>
			<template v-else>
				<div class="h-16"></div>
			</template>

			<form-group type="password" name="password" left-icon="lock-closed-outline" v-model="form.data.password.value" :form="form">
				Enter Password
			</form-group>

			<form-group type="password" name="passwordagain" left-icon="lock-closed-outline" v-model="form.data.passwordagain.value" :form="form">
				Repeat New Password
			</form-group>

			<div class="text-center">
				<button type="submit" class="btn btn-blue btn-md w-full" :disabled="form.loading">
					<span v-if="form.loading">Submitting...</span>
					<span v-else>Set Password</span>
				</button>
			</div>

		</form>

	</div>
</template>

<script>
	import { mapActions } from 'vuex';

	export default {
		data() {
			return {
				form: this.$options.basicForm([
					{name: 'password', value: '', rules: 'required|min:8|alphaNum'},
					{name: 'passwordagain', value: '', rules: 'required|same:password'},
				])
			}
		},
		computed: {
			errorMessage() {
				if (this.form.error.toString().match(/Error: Network Error/i)) {
					return 'Please check your internet connection';
				}

				return null;
			},
			sessionExpired() {
				return this.$route.query.expired;
			}
		},
		mounted() {
			this.$alert({
				title: 'Secure Your Account.',
				body: 'Create a password to continue using credpal.',
				button: 'Continue'
			});
		},
		methods: {
			...mapActions('session', ['updateUser']),

			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.loading = true;
				await this.$post({
					url: `${this.$baseurl}/users/password/change`,
					data: {
						userid: this.user.id,
						password: this.form.data.password.value,
						passwordagain: this.form.data.passwordagain.value
					},
					headers: this.headers,
					success: (response) => {
						this.updateUser(response.data.user);
						this.$success({
							title: 'Password Changed',
							body: 'Your password has been updated.',
							button: 'Go to dashboard',
							onClose: () => this.$router.push({ name: 'root' })
						});
					},
					error: error => {
						this.form.error = error;
						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});
				this.form.loading = false;
			}
		}
	}
</script>